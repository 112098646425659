.Signup .lander {
  padding: calc(((100vh - 648px)/2)) 16px 0px 16px;
  min-height: calc(100vh - 84px);
}


@media all and (min-width: 480px) {
  .Signup {
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}
@media all and (max-width: 479px) {
  .Signup {
  }

  .Signup form {
    margin: 0px auto;
    max-width: 280px;
  }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
