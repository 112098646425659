.NewOverlay .lander {
  padding: 0px 16px 0px 16px;
}
.NewOverlay form textarea {
  height: 300px;
  font-size: 1.5rem;
}
*:focus {
    outline: none;
}
