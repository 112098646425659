.Map .lander {
  padding: 0px 0px;
  text-align: left;
  min-height: calc(100vh - 188px);
}
.Map .lander *:focus{
  outline: none;
}
text {
  user-select: none;
}
