* {
  font-family: 'Montserrat', sans-serif;
}

.App {

}
.App .mobile-container {

}

.container {
    max-width: 100%;
}
