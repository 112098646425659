.NewUser .lander {
  padding: 0px 16px 0px 16px;
}
.NewUser form textarea {
  height: 300px;
  font-size: 1.5rem;
}
*:focus {
    outline: none;
}
