

.Thinking .lander {
  padding: 40px 40px;
  text-align: left;
  min-height: calc(100vh - 168px);

/*
  background-image: url(../images/about.jpg);

  height: auto;
  width: 100%;

  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  */
}
.Thinking .mobile-lander {
  padding: 40px 40px;
  text-align: left;
  min-height: calc(100vh - 168px);

/*
  background-image: url(../images/about.jpg);

  height: auto;
  width: 100%;


  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  */
}
.Thinking .lander img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

.Thinking .lander h2 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: #aa0000;
font-size: 32px;
}
.Thinking .mobile-lander h2 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: black;
font-size: 24px;

}
