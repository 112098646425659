

.ListData .lander {
  padding: 40px 40px;
  text-align: left;
  min-height: calc(100vh - 84px);

/*
  background-image: url(../images/about.jpg);

  height: auto;
  width: 100%;

  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  */
}
.ListData .mobile-lander {
  padding: 40px 40px;
  text-align: left;
  min-height: calc(100vh - 84px);

/*
  background-image: url(../images/about.jpg);

  height: auto;
  width: 100%;


  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  */
}


.ListData .lander img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

.ListData .lander h2 {
  /*font-family: 'Comfortaa', cursive;
  font-weight: 900;*/
  color: #aaa;
font-size: 24px;
}
.ListData .mobile-lander h2 {
  /*font-family: 'Comfortaa', cursive;
  font-weight: 900;*/
  color: #aaa;
font-size: 22px;

}
